import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import Flags from './resources/CountryFlags.csv';
import Logos from './resources/ClubLogos.csv';
import defaultImage from './resources/default_player.png';
import './search-bar.css';
import unaccent from 'unaccent';

// Function to fetch and set country flags
export const fetchFlags = (setFlagsCallback, setFlagsLoaded) => {
    Papa.parse(Flags, {
        download: true,
        header: true,
        complete: function (results) {
            const flagInfo = results.data.map(row => ({
                nationality: row.Nationality,
                flag: row.Flag
            }));
            setFlagsCallback(flagInfo);
            setFlagsLoaded(true);
        }
    });
};

// Function to fetch and set club logos
export const fetchLogos = (setLogosCallback, setLogosLoaded) => {
    Papa.parse(Logos, {
        download: true,
        header: true,
        complete: function (results) {
            const clubInfo = results.data.map(row => ({
                club: row.Club,
                logo: row['Club Logo']
            }));
            setLogosCallback(clubInfo);
            if (setLogosLoaded !== null) {
                setLogosLoaded(true);
            }
        }
    });
};

const SearchBar = ({ onSearch, playerData, setPlayerData, token }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const [playersPerPage] = useState(10);
    const [numPages, setNumPages] = useState(0);
    const [numResults, setNumResults] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [sortBy, setSortBy] = useState('Overall');

    //Pages variables
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRange, setPageRange] = useState({ start: 1, end: Math.min(10, numPages) });

    //Other filter features
    const [positionFilter, setPositionFilter] = useState('');
    const [ageRange, setAgeRange] = useState({ min: '', max: '' });
    const [heightRange, setHeightRange] = useState({ min: '', max: '' });
    const [teamSearchTerm, setTeamSearchTerm] = useState('');
    const [teamFilter, setTeamFilter] = useState({ name: '', logo: '' });
    const [sortOrder, setSortOrder] = useState('desc');

    //Variables for countryFlags and clubLogos from .csv files
    const [flags, setFlags] = useState([]);
    const [logos, setLogos] = useState([]);
    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const [logosLoaded, setLogosLoaded] = useState(false);

    //Variables for club search results
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        fetchFlags(setFlags, setFlagsLoaded);
        fetchLogos(setLogos, setLogosLoaded);
    }, []);

    useEffect(() => {
        const parseHeight = (height) => {
            if (height === null) {
                return height;
            }

            // Check if the height is already in the correct format
            const correctFormatRegex = /^\d+cm \/ \d+'\d+(\.\d)?"$/;
            if (correctFormatRegex.test(height)) {
                return height;
            }

            let cmHeight = height;
            if (cmHeight.includes('cm')) {
                cmHeight = parseInt(height.replace('cm', '').trim(), 10);
            } else {
                cmHeight = parseInt(height.trim(), 10);
            }

            if (isNaN(cmHeight)) {
                return null; // Return null if the input is not a valid number
            }

            const totalInches = cmHeight / 2.54;
            const feet = Math.floor(totalInches / 12);
            let inches = (totalInches % 12).toFixed(1);

            if (inches.endsWith('.0')) {
                inches = inches.split('.')[0];
            }
            return `${cmHeight}cm / ${feet}'${inches}"`;
        };

        const fetchPlayers = async () => {
            let allPlayers = [];
            let page = 1;
            let totalPlayers = 0;

            try {
                do {
                    const response = await axios.get('https://footballcustomformation.com/php_backend/fetch_players.php', {
                        params: {
                            page: page
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        withCredentials: true
                    });

                    const data = response.data;

                    // Add the new players to the allPlayers array
                    allPlayers = allPlayers.concat(data.players);

                    // Update the totalPlayers and increment the page
                    totalPlayers = data.totalPlayers;
                    page++;

                } while (allPlayers.length < totalPlayers);

                // Process all players
                const playerStuff = allPlayers.map(row => {
                    if (row) {
                        const player = {
                            id: row.id,
                            name: row.Name,
                            fullName: row['Long Name'],
                            age: row.Age,
                            height: parseHeight(row.Height),
                            weight: row.Weight,
                            photo: updatePlayerImage(row.id),
                            nationality: row.Nationality,
                            countryNumber: row.nation_jersey_number || 0,
                            flag: row.Flag,
                            overall: row.Overall,
                            potential: row.Potential,
                            club: row.Club,
                            clubLogo: row['Club Logo'],
                            clubNumber: row['kit number'] || 0,
                            position: row.Position,
                            currentPosition: '',
                            wage: row.Wage,
                            value: row.Value,
                            prefFoot: row['Preferred Foot'],
                            weakFoot: row['Weak Foot'],
                            skillMoves: row['Skill Moves'],
                            intRep: row['International Rep'],
                            pace: row.pace,
                            shooting: row.shooting,
                            passing: row.passing,
                            dribbling: row.dribbling,
                            defending: row.defending,
                            physical: row.physic,
                            crossing: row.attacking_crossing,
                            finishing: row.attacking_finishing,
                            headingAccuracy: row.attacking_heading_accuracy,
                            shortPassing: row.attacking_short_passing,
                            volleys: row.attacking_volleys,
                            skill_dribbling: row.skill_dribbling,
                            curve: row.skill_curve,
                            fkAccuracy: row.skill_fk_accuracy,
                            longPassing: row.skill_long_passing,
                            ballControl: row.skill_ball_control,
                            acceleration: row.movement_acceleration,
                            sprintSpeed: row.movement_sprint_speed,
                            agility: row.movement_agility,
                            reactions: row.movement_reactions,
                            balance: row.movement_balance,
                            shotPower: row.power_shot_power,
                            jumping: row.power_jumping,
                            stamina: row.power_stamina,
                            strength: row.power_strength,
                            longShots: row.power_long_shots,
                            aggression: row.mentality_aggression,
                            interceptions: row.mentality_interceptions,
                            positioning: row.mentality_positioning,
                            vision: row.mentality_vision,
                            penalties: row.mentality_penalties,
                            composure: row.mentality_composure,
                            marking: row.defending_marking_awareness,
                            standingTackle: row.defending_standing_tackle,
                            slidingTackle: row.defending_sliding_tackle,
                            gkDiving: row.goalkeeping_diving,
                            gkHandling: row.goalkeeping_handling,
                            gkKicking: row.goalkeeping_kicking,
                            gkReflex: row.goalkeeping_reflexes,
                            gkSpeed: row.goalkeeping_speed,
                            gkPos: row.goalkeeping_positioning,
                            LS: row.ls, ST: row.st, RS: row.rs,
                            CF: row.cf, RF: row.rf, LF: row.lf,
                            LW: row.lw, RW: row.rw, LAM: row.lam, CAM: row.cam, RAM: row.ram,
                            LM: row.lm, LCM: row.lcm, RCM: row.rcm, RM: row.rm, CM: row.cm,
                            LB: row.lb, LWB: row.lwb,
                            LDM: row.ldm, CDM: row.cdm, RDM: row.rdm,
                            RB: row.rb, RWB: row.rwb,
                            LCB: row.lcb, CB: row.cb, RCB: row.rcb,
                            GK: row.gk, fotMobLink: row.fotMobLink,
                            goals: row.goals, assists: row.assists, form: row.form, cleanSheets: row.clean_sheets
                        };

                        if (!player.flag && flags.length > 0) {
                            const countryData = flags.find(item => player.nationality.includes(item.nationality));
                            if (countryData) {
                                player.flag = countryData.flag;
                            } else {
                                player.flag = defaultImage;
                            }
                        }

                        if (!player.clubLogo && logos.length > 0) {
                            const clubData = logos.find(item => item.club.includes(player.club));
                            if (clubData) {
                                player.clubLogo = clubData.logo;
                            } else {
                                player.clubLogo = defaultImage;
                            }
                        }

                        return player;
                    }
                    return null;
                });

                setPlayerData(playerStuff);
                console.log('Finished getting Players');
            } catch (error) {
                console.error('There was an error!', error);
                console.error(`Token: ${token}`);
            }
        }
        if (flagsLoaded && logosLoaded) {
            fetchPlayers();
        }
    }, [flagsLoaded, logosLoaded]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowResults(false);
        }
    };

    useEffect(() => {
        if (showResults) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showResults]);

    //Use effect for searching up teams in menu
    useEffect(() => {
        if (logosLoaded && flagsLoaded) {
            const term = teamSearchTerm.toLowerCase();

            if (term === '') {
                setTeamFilter({ name: '', logo: '' });
                setSearchResults([]);
                return;
            }

            const combinedSearchItems = [
                ...logos.map(club => ({ type: 'club', name: club.club, image: club.logo })),
                ...flags.map(flag => ({ type: 'country', name: flag.nationality, image: flag.flag })),
            ];

            const matchingItems = combinedSearchItems.filter(item => {
                const refinedTerm = enhancedUnaccent(item.name);
                return refinedTerm.toLowerCase().includes(term);
            });

            setSearchResults(matchingItems);
            setShowResults(true);
        } else {
            setShowResults(false);
        }
    }, [teamSearchTerm, flagsLoaded, logosLoaded]);

    const updatePlayerImage = (playerId) => {
        if (playerId) {
            const paddedId = playerId.toString().padStart(6, '0');

            const part1 = paddedId.substring(0, 3);
            const part2 = paddedId.substring(3);
            const imageUrl = `https://cdn.sofifa.net/players/${part1}/${part2}/25_120.png`;
            return imageUrl;
        }
    }

    /** Fix for exclusion of u accents in 'unaccent' */
    function enhancedUnaccent(text) {
        // First, use unaccent to remove most accents
        let normalizedText = unaccent(text);

        // Manually replace any specific characters missed by unaccent
        normalizedText = normalizedText.replace(/ú/g, 'u');

        return normalizedText;
    }

    const handleSearch = () => {
        const normalizedSearchTerm = enhancedUnaccent(searchTerm.replace(/^\s+/, '').toLowerCase().trim()).split(/\s+/);

        // Filter player data based on the search term
        const filteredPlayers = playerData.filter(player => {
            if (player && (player.fullName || player.name)) {
                const fullNameNormalized = player.fullName ? enhancedUnaccent(player.fullName.toLowerCase().replace(/\s+/g, '')) : '';
                const nameNormalized = player.name ? enhancedUnaccent(player.name.toLowerCase().replace(/\s+/g, '')) : '';

                const isFullNameMatch = normalizedSearchTerm.every(term => fullNameNormalized.includes(term));
                const isNameMatch = normalizedSearchTerm.every(term => nameNormalized.includes(term));
                const isIdMatch = normalizedSearchTerm.some(term => player.id && player.id.includes(term));

                return isFullNameMatch || isNameMatch || isIdMatch;
            }
            return false;
        });

        const sortedPlayers = filteredPlayers.sort((a, b) => {
            if (sortBy === 'Name') {
                const aName = a.name.toLowerCase(); // Assuming 'name' is the key for player names
                const bName = b.name.toLowerCase();

                if (aName < bName) return sortOrder === 'asc' ? -1 : 1;
                if (aName > bName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            } else {
                const aValue = parseInt(a[sortBy.toLowerCase()]);
                const bValue = parseInt(b[sortBy.toLowerCase()]);
                if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            }
        });

        setNumPages(Math.ceil(sortedPlayers.length / playersPerPage));
        setFiltered(sortedPlayers);
        setNumResults(sortedPlayers.length);
        updateCurrentPlayers(sortedPlayers, 1);
    };

    const filterByCriteria = () => {
        let filteredPlayers = playerData;
        if (positionFilter) {
            filteredPlayers = filteredPlayers.filter(player => {
                if (player.position) {
                    let positions = player.position;
                    if (player.position.includes(';')) {
                        positions = player.position.split(';').map(pos => pos.trim());
                    }
                    return positions.includes(positionFilter) || positions === positionFilter;
                }
                return false;
            });
        }

        if (teamFilter && teamFilter.name !== '') {
            //console.log('Team Filter', teamFilter);
            if (teamFilter.type === 'club') {
                filteredPlayers = filteredPlayers.filter(player => {
                    return (player.club && player.club.toLowerCase().trim() === teamFilter.name.toLowerCase().trim())
                });
            } else {
                filteredPlayers = filteredPlayers.filter(player => {
                    return (player.nationality && player.nationality.toLowerCase().includes(teamFilter.name.toLowerCase())) ||
                        (player.flag && player.flag === teamFilter.image);
                });
            }
            setSearchTerm('');
        }

        if (ageRange.min !== '' || ageRange.max !== '') {
            filteredPlayers = filteredPlayers.filter(player => {
                if (player.age) {
                    const age = player.age.length > 2 ? parseInt(player.age.split(';')[1], 10) : player.age;
                    return (ageRange.min === '' || age >= ageRange.min) && (ageRange.max === '' || age <= ageRange.max);
                }
                return false;
            });
        }

        if (heightRange.min !== '' || heightRange.max !== '') {
            console.log('Height range', heightRange);
            filteredPlayers = filteredPlayers.filter(player => {
                if (player.height) {
                    const height = parseInt(player.height.split('cm')[0]);
                    return (heightRange.min === '' || height >= heightRange.min) && (heightRange.max === '' || height <= heightRange.max);
                }
                return false;
            });
        }

        const sortedPlayers = filteredPlayers.sort((a, b) => {
            if (sortBy === 'Name') {
                const aName = a.name.toLowerCase(); // Assuming 'name' is the key for player names
                const bName = b.name.toLowerCase();

                if (aName < bName) return sortOrder === 'asc' ? -1 : 1;
                if (aName > bName) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            } else {
                const aValue = parseInt(a[sortBy.toLowerCase()]);
                const bValue = parseInt(b[sortBy.toLowerCase()]);
                if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            }
        });

        setFiltered(sortedPlayers);
        setNumResults(sortedPlayers.length);
        setNumPages(Math.ceil(sortedPlayers.length / playersPerPage));
        updateCurrentPlayers(sortedPlayers, 1);
    }

    const updateCurrentPlayers = (filteredPlayers, page) => {
        const startIndex = (page - 1) * playersPerPage;
        const selectedPlayers = filteredPlayers.slice(startIndex, startIndex + playersPerPage);
        //console.log("Player names array:", selectedPlayers); // Log player names array
        onSearch(selectedPlayers);
    }

    const handleChangePage = (newPage) => {
        if (numPages <= 10) {
            setCurrentPage(newPage);
            updateCurrentPlayers(filtered, newPage);
            return;
        }

        let newStart = pageRange.start;
        let newEnd = pageRange.end;

        if (newPage > pageRange.end) {
            newStart = Math.min(newPage, numPages - 9);
            newEnd = Math.min(newPage + 9, numPages);
        } else if (newPage < pageRange.start) {
            newStart = Math.max(newPage - 9, 1);
            newEnd = newStart + 9;
        } else if (newPage <= 10) {
            newStart = 1;
            newEnd = Math.min(10, numPages);
        } else if (newPage >= numPages - 9) {
            newStart = Math.max(numPages - 9, 1);
            newEnd = numPages;
        }

        setPageRange({ start: newStart, end: newEnd });
        setCurrentPage(newPage);
        updateCurrentPlayers(filtered, newPage);
    }

    const handleDropdownPageChange = (e) => {
        const newPage = Number(e.target.value);
        handleChangePage(newPage);
    }

    const handleSearchChange = (e) => {
        const value = e.target.value || ''; // If event.target.value is undefined or null, use an empty string
        setSearchTerm(value);
    };

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    }

    const handlePositionChange = (e) => {
        setPositionFilter(e.target.value);
    }

    const handleAgeRangeChange = (e) => {
        const { name, value } = e.target;
        setAgeRange(prevRange => ({ ...prevRange, [name]: value === '' ? '' : Number(value) }));
    }

    const handleHeightRangeChange = (e) => {
        const { name, value } = e.target;
        setHeightRange(prevHeight => ({ ...prevHeight, [name]: value === '' ? '' : Number(value) }));
    };

    const handleSelectTeam = (team) => {
        console.log('Current team', team);
        setTeamFilter(team);
        setTeamSearchTerm(team.name);
        setShowResults(false);
    }

    return (
        <div className="search-bar">
            <input
                type="text"
                className="search-input"
                placeholder="Search for a player"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
            <button onClick={handleSearch} className="search-button">Search</button>

            <select onChange={handleSortChange} value={sortBy}>
                <option value="overall">Overall</option>
                <option value="name">Name</option>
                <option value="potential">Potential</option>
                <option value="acceleration">Acceleration</option>
                <option value="sprintSpeed">Sprint Speed</option>
                <option value="positioning">Alt Positioning</option>
                <option value="finishing">Finishing</option>
                <option value="shotPower">Shot Power</option>
                <option value="penalties">Penalties</option>
                <option value="standingTackle">Standing Tackle</option>
                <option value="slidingTackle">Sliding Tackle</option>
                <option value="interceptions">Interceptions</option>
                <option value="jumping">Jumping</option>
            </select>

            <select onChange={handlePositionChange} value={positionFilter}>
                <option value="">Select Position</option>
                <option value="CF">CF</option>
                <option value="CB">CB</option>
                <option value="CAM">CAM</option>
                <option value="CDM">CDM</option>
                <option value="CM">CM</option>
                <option value="LB">LB</option>
                <option value="LWB">LWB</option>
                <option value="LW">LW</option>
                <option value="LM">LM</option>
                <option value="LW">LW</option>
                <option value="RB">RB</option>
                <option value="RWB">RWB</option>
                <option value="RW">RW</option>
                <option value="RM">RM</option>
                <option value="ST">ST</option>
                <option value="GK">GK</option>
            </select>

            <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
            </select>

            <div className="filter-container">
                <label>Sort by Age</label>
                <div className="input-pair">
                    <input
                        type="number"
                        name="min"
                        placeholder="Min Age"
                        value={ageRange.min}
                        onChange={handleAgeRangeChange}
                        className="small-input"
                        min={16}
                        max={50}
                    />
                    <input
                        type="number"
                        name="max"
                        placeholder="Max Age"
                        value={ageRange.max}
                        onChange={handleAgeRangeChange}
                        className="small-input"
                        min={16}
                        max={50}
                    />
                </div>
            </div>

            <div className="filter-container">
                <label>Sort by Height (cm)</label>
                <div className="input-pair">
                    <input
                        type="number"
                        name="min"
                        placeholder="Min Height"
                        value={heightRange.min}
                        onChange={handleHeightRangeChange}
                        className="small-input height-input"
                        min={100}
                        max={250}
                    />

                    <input
                        type="number"
                        name="max"
                        placeholder="Max Height"
                        value={heightRange.max}
                        onChange={handleHeightRangeChange}
                        className="small-input height-input"
                        min={100}
                        max={250}
                    />
                </div>
            </div>


            {/* Similar to dropdown in TeamViewer.css */}
            <div className="search-dropdown" ref={dropdownRef}>
                <input
                    type="text"
                    placeholder="Search for a club or national team..."
                    value={teamSearchTerm}
                    onChange={(e) => setTeamSearchTerm(e.target.value)}
                    onFocus={() => setShowResults(true)}
                    autoFocus
                />
                {showResults && searchResults.length > 0 && (
                    <ul className="search-dropdown-results">
                        {searchResults.map((item, index) => (
                            <li key={index} onClick={() => handleSelectTeam(item)}>
                                <img src={item.image} alt={item.name} /> {item.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <button onClick={filterByCriteria} className="filter-button">Filter</button>
            {numResults > 0 && (
                <div>
                    <p>{numResults} results found</p>
                    <div>
                        {Array.from({ length: Math.min(numPages, 10) }, (_, i) => (
                            <button className="change-button" key={i + pageRange.start} onClick={() => handleChangePage(i + pageRange.start)}>
                                {i + pageRange.start}
                            </button>
                        ))}

                        {numPages > 10 && (
                            <select onChange={handleDropdownPageChange} value={currentPage}>
                                {Array.from({ length: numPages }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
            )}
        </div>

    );
};

export default SearchBar;