import axios from 'axios';
import { simulateMatch } from './SimulateMatch';
import { findBestFormation, getBenchPositions, determineBenchPlayers, createPositionsData } from './FindBestFormation';
const missingPlayers = [];

const addToLeague = async (team, addOrEdit) => {
    function extractPlayerIds(playersArray) {
        return playersArray.map(player => player.id);
    }

    const newStartingXI = Object.values(team.starting).map(player => ({
        id: player.id,
        currentPosition: player.currentPosition,
    }));
    const formattedTeam = {
        team_id: team.teamId,
        team_name: team.teamName,
        startingXI: newStartingXI,
        bench: extractPlayerIds(team.sub),
        reserves: extractPlayerIds(team.res),
        dropFormation: team.dropFormation,
        formation: team.formation,
        captain: team.captain
    };

    console.log('Formatted team:', formattedTeam);
    if (addOrEdit === 'add') {
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/add_to_league.php', {
                team: formattedTeam
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            console.log('Add To league response:', response);
        } catch (error) {
            console.error('Problem with saving formatted team:', error.response);
        }
    } else {
        try {
            const response = await axios.post('https://footballcustomformation.com/php_backend/save_team.php', {
                team: formattedTeam
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            console.log('Add To league response:', response);
        } catch (error) {
            console.error('Problem with saving formatted team:', error.response);
        }
    }

};

const generateFixtures = (teams) => {
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const shuffledTeams = shuffleArray(teams);
    const fixtures = [];
    const totalRounds = (shuffledTeams.length - 1) * 2;
    const totalMatchesPerRound = shuffledTeams.length / 2;

    for (let round = 0; round < totalRounds; round++) {
        const roundFixtures = [];
        for (let match = 0; match < totalMatchesPerRound; match++) {
            const team1Index = (round + match) % (shuffledTeams.length - 1);
            const team2Index = (shuffledTeams.length - 1 - match + round) % (shuffledTeams.length - 1);

            const team1 = shuffledTeams[team1Index];
            const team2 = shuffledTeams[team2Index === team1Index ? shuffledTeams.length - 1 : team2Index];

            if (round % 2 === 0) {
                roundFixtures.push({ home: team1, away: team2 });
            } else {
                roundFixtures.push({ home: team2, away: team1 });
            }
        }
        fixtures.push(roundFixtures);
    }
    return fixtures;
};

const updateLeagueTable = (leagueTable, team, goalsFor, goalsAgainst) => {
    const tableTeam = leagueTable.find(t => t.displayName === team.displayName);

    tableTeam.played++;
    tableTeam.goals_for += goalsFor;
    tableTeam.goals_against += goalsAgainst;
    tableTeam.goal_difference = tableTeam.goals_for - tableTeam.goals_against;

    if (goalsFor > goalsAgainst) {
        tableTeam.wins += 1;
        tableTeam.points += 3;
    } else if (goalsFor < goalsAgainst) {
        tableTeam.losses += 1;
    } else {
        tableTeam.draws += 1;
        tableTeam.points += 1;
    }
};

const initializeAppearances = (teams) => {
    teams.forEach(team => {
        [...Object.values(team.positionsData), ...team.bench, ...team.reserves].forEach(player => {
            if (!player.appearances) {
                player.appearances = 0;
                player.currentStamina = 100;
            }
        });
    });
};

const updateCleanSheets = (homeTeam, awayTeam, homeGoals, awayGoals) => {
    if (awayGoals === 0) {
        const homeGoalkeeper = homeTeam.positionsData['Goalkeeper-0'];
        if (homeGoalkeeper) {
            homeGoalkeeper.cleanSheets = (homeGoalkeeper.cleanSheets || 0) + 1;
        }
    }

    if (homeGoals === 0) {
        const awayGoalkeeper = awayTeam.positionsData['Goalkeeper-0'];
        if (awayGoalkeeper) {
            awayGoalkeeper.cleanSheets = (awayGoalkeeper.cleanSheets || 0) + 1;
        }
    }
};

const findPlayerInTeam = (playerName, team) => {
    const allPlayers = [...Object.values(team.positionsData), ...team.bench, ...team.reserves];
    const player = allPlayers.find(player => player.name === playerName);
    // if (!player) {
    //      console.log(`${playerName} can't be found in ${team.displayName}`, allPlayers);
    // }
    return player;
}

const trackCards = (leagueId, result, playerCards) => {
    const yellowCardCount = {};

    if (result.yellowCards.length > 0) {
        result.yellowCards.forEach(card => {
            const playerName = `${card.name}-${card.team}`;
            //console.log(`${playerName} got a YELLOW card!`);
            if (!playerCards[playerName]) {
                playerCards[playerName] = { team: card.team, yellowCards: 0, redCards: 0, suspension: 0, injured: false };
            }
            playerCards[playerName].yellowCards++;

            if (!yellowCardCount[playerName]) {
                yellowCardCount[playerName] = 0;
            }
            yellowCardCount[playerName]++;

            if (yellowCardCount[playerName] === 2) {
                playerCards[playerName].suspension++;
                //console.log(`${playerName} received a red card from two yellows and is suspended for 1 match in round ${result.round}.`);
            }

            //Checks if player has accumulated enough cards for a suspension
            if (playerCards[playerName].yellowCards === 5) {
                //console.log(`${playerName} has received 5 yellow cards.\nWill be suspended for 1 game`);
                playerCards[playerName].suspension++;
            } else if (playerCards[playerName].yellowCards === 10) {
                //console.log(`${playerName} has received 10 yellow cards.\nWill be suspended for 2 games`);
                playerCards[playerName].suspension += 2;
            }
        });
    }

    if (result.redCards.length > 0) {
        result.redCards.forEach(card => {
            const playerName = `${card.name}-${card.team}`;
            //console.log(`${playerName} got a RED card in round ${result.round}!`);
            if (!playerCards[playerName]) {
                playerCards[playerName] = {
                    team: card.team,
                    yellowCards: 0,
                    redCards: 0,
                    suspension: 0
                };
            }
            playerCards[playerName].redCards++;

            if (leagueId === 13) {
                playerCards[playerName].suspension += 3;
            } else if (leagueId === 53) {
                playerCards[playerName].suspension += 2;
            } else if (leagueId === 16) {
                playerCards[playerName].suspension += 1;
            }
        });
    }

    //console.log('player cards:', playerCards); 
    //Player cards are being added here
};

const decreaseSuspensions = (playerCards) => {
    Object.keys(playerCards).forEach(playerName => {
        if (playerCards[playerName].suspension > 0) {
            playerCards[playerName].suspension--;
            //console.log(`${playerName} stats after suspension decrease`, playerCards[playerName].suspension);
        }
    });
};

let replacementHistory = [];

const adjustTeamForSuspensions = (team, playerCards) => { //playerCards ARE defined
    // Debug stuff
    const originalTeam = JSON.parse(JSON.stringify(team));

    /** Check for if any player has disappeared */
    const checkPlayersInTeam = () => {
        const teamPlayers = [...Object.values(team.positionsData), ...team.bench, ...team.reserves];
        const teamPlayerIds = new Set(teamPlayers.map(p => p.id));
        const currentTeamPlayers = [...Object.values(originalTeam.positionsData), ...originalTeam.bench, ...originalTeam.reserves];
        currentTeamPlayers.forEach(player => {
            if (!teamPlayerIds.has(player.id)) {
                console.error(`Original team is missing ${player.name} in team players:`, originalTeam, '\nTeam', team);
            }
        });
    };

    const isSuspended = (player) => {
        const playerKey = `${player.name}-${team.displayName}`;
        return playerCards[playerKey]?.suspension > 0;
    };

    const createNewTeam = (startingPlayers) => { //Players don't disappear here
        //Filters out suspended players and finds new team based on remaning players
        const suspendedReserves = originalTeam.reserves.filter(p => isSuspended(p));
        const newTeam = findBestFormation(startingPlayers, originalTeam.bench, originalTeam.reserves.filter(p => !isSuspended(p)), originalTeam.dropFormation);
        //console.log('New team after replacement:', newTeam);
        originalTeam.positionsData = createPositionsData(newTeam.positionNames, newTeam.bestFormation.playerPositions, originalTeam.formation);
        originalTeam.bench = newTeam.bench;
        const fullReserves = [...newTeam.reserves, ...suspendedReserves];
        originalTeam.reserves = fullReserves;
    }

    const createNewSubstitutes = () => {
        const teamFormationObj = team.formation;
        const benchPositions = getBenchPositions(teamFormationObj);
        const suspendedReserves = originalTeam.reserves.filter(p => isSuspended(p));
        const allPlayers = [...Object.values(originalTeam.positionsData), ...originalTeam.bench, ...originalTeam.reserves.filter(p => !isSuspended(p))];
        //console.log('All possible players:', allPlayers);
        const usedPlayers = new Set(Object.values(originalTeam.positionsData).map(p => p.id));
        //Gets new bench and reserves from imported determineBenchPlayers function
        const { bench, reserves } = determineBenchPlayers(allPlayers, usedPlayers, benchPositions);
        // console.log('Old bench:', originalTeam.bench, '\nOld reserves:', originalTeam.reserves);
        // console.log('New bench:', bench, 'New reserves:', reserves);
        originalTeam.bench = bench;
        originalTeam.reserves = [...reserves, ...suspendedReserves];
        //console.log('Resulting team:', originalTeam);
        checkPlayersInTeam();
    }

    //Make sure this is not empty
    const suspendedPlayers = Object.keys(playerCards).filter(playerName =>
        playerCards[playerName].suspension > 0 &&
        playerCards[playerName].team.includes(originalTeam.displayName) &&
        !playerCards[playerName].isBeingReplaced
    );

    const findPositionKeyById = (team, playerId) => {
        return Object.keys(team.positionsData).find(
            key => parseInt(team.positionsData[key].id) === parseInt(playerId)
        );
    };

    suspendedPlayers.forEach(playerKey => {
        const playerName = playerKey.split('-').slice(0, -1).join('-');
        const suspendedPlayer = findPlayerInTeam(playerName, team);
        if (!suspendedPlayer) {
            console.error(`Could not find ${playerName} in ${originalTeam.displayName}`);
            return;
        }  //NEW: Skip if the player is not found in the current team

        const suspendedPosition = findPositionKeyById(originalTeam, suspendedPlayer.id);

        const wasOnBench = originalTeam.bench.some(player => player.name === playerName);
        playerCards[playerKey].wasOnBench = wasOnBench; // Set the flag
        playerCards[playerKey].isBeingReplaced = true;
        console.log(`${playerName} will be replaced in ${suspendedPosition} in ${team.displayName}. On bench? ${wasOnBench}`);
        if (wasOnBench) {
            // If player was on bench, move suspendedPlayer to reserves and run getBench players
            //Push suspended bench player to reserves
            //console.log(`${suspendedPlayer.name} in ${team.displayName} was on bench`);
            originalTeam.reserves.push(suspendedPlayer);
            originalTeam.bench = originalTeam.bench.filter(p => parseInt(p.id) !== parseInt(suspendedPlayer.id));

            createNewSubstitutes();
        } else { //Player was in starting 11 and will be filtered to reserves
            //Remove player from starting 11:
            delete originalTeam.positionsData[suspendedPosition];
            const originalPlayersIds = new Set(Object.values(originalTeam.positionsData).map(p => p.id));
            //console.log('Previous positionsData:', originalTeam.positionsData);
            //Add to reserves
            originalTeam.reserves.push(suspendedPlayer);
            createNewTeam(Object.values(originalTeam.positionsData));
            const missingPlayer = Object.values(originalTeam.positionsData).find(player => !originalPlayersIds.has(player.id));
            //console.log(`New player addition for: ${suspendedPlayer.name}`, missingPlayer);
            if (!suspendedPlayer.isStarter) console.log(`${suspendedPlayer.name} was not a starter and was suspended`);
            replacementHistory.push({
                player: suspendedPlayer.id, 
                replacement: missingPlayer,
                origin: 'starting',
            });
            checkPlayersInTeam();
        }
        //console.log('Updated team:', originalTeam);
    });

    //Put players back in proper positions
    Object.keys(playerCards).forEach(playerKey => {
        if (playerCards[playerKey].suspension === 0 && playerCards[playerKey].isBeingReplaced) {
            const playerName = playerKey.split('-').slice(0, -1).join('-');
            const suspendedPlayer = findPlayerInTeam(playerName, team);

            if (suspendedPlayer && playerCards[playerKey].team === originalTeam.displayName) {
                //console.log(`${suspendedPlayer.name} to be returned to ${team.displayName} `);
                //Case for if player was in originalPositions data
                const startingPlayers = Object.values(originalTeam.positionsData);
                if (suspendedPlayer.isStarter) {
                    //Keep looking at replacement until they are not in reserves
                    let currentReplacement = suspendedPlayer.id;
                    let foundInStarting = false;

                    while (!foundInStarting) {
                        const historyEntry = replacementHistory.find(entry => entry.player === currentReplacement);
                        if (!historyEntry) break;
                        //console.log('Current history entry:', historyEntry);
                        const replacementInStarting = startingPlayers.find(player => player.id === historyEntry.replacement.id);
                        //console.log('Replacement found?:', replacementInStarting || 'not found');

                        if (replacementInStarting) { //This works
                            const replacementIndex = startingPlayers.findIndex(p => p.id === historyEntry.replacement.id);
                            startingPlayers[replacementIndex] = suspendedPlayer; //Place suspended Player here
                            originalTeam.reserves.filter(p => p.id !== suspendedPlayer.id); //Removes suspended player from reserves
                            originalTeam.reserves.push(replacementInStarting);
                            foundInStarting = true; //Found replacement and loop ends
                            replacementHistory = replacementHistory.filter(entry => entry.player !== currentReplacement);
                        } else {
                            //If not in starting, then look in reserves
                            //console.log('Replacement player was not found in reserves, looking for next entry');
                            const replacementInReserves = originalTeam.reserves.find(p => p.id === historyEntry.replacement.id);
                            if (replacementInReserves) {
                                //console.log(`${replacementInReserves.name} was found in reserves. Finding their replaement next`);
                                replacementHistory = replacementHistory.filter(entry => entry.player !== currentReplacement);
                                currentReplacement = historyEntry.replacement.id;
                            } else {
                                const replacementInBench = originalTeam.bench.find(p => p.id === historyEntry.replacement.id)
                                //Find player in bench
                                if (replacementInBench) {
                                    //console.log(`${replacementInBench.name} was found in bench. Finding their replacement next`);
                                    replacementHistory = replacementHistory.filter(entry => entry.player !== currentReplacement);
                                    currentReplacement = historyEntry.replacement.id;
                                } else {
                                    console.error(`${suspendedPlayer.name} was suspended and ${historyEntry.replacement.name} disappeared in ${team.displayName}. Keep debugging in`, originalTeam);
                                    break;
                                }
                            }
                        }
                    }

                    //Now remake team with starting player back
                    //console.log('Resulting starting team:', startingPlayers);
                    createNewTeam(startingPlayers);
                    checkPlayersInTeam();
                } else { //Case where suspended player was originally from bench/reserves
                    //Recreates bench and reserves
                    //console.log(`${suspendedPlayer.name} was not a starter`);
                    createNewSubstitutes();
                }

                delete playerCards[playerKey].isBeingReplaced;
                //console.log(`Resulting ${originalTeam.displayName} after suspension adjustment:`, originalTeam);
            }
        }
    });

    return originalTeam;
};

const handleSuspensionsAndAdjustments = async (teams, playerCards) => {
    //console.log('Teams that will be checked for suspensions:', teams);
    const newTeams = teams.map(team => {
        return adjustTeamForSuspensions(team, playerCards);
    });

    decreaseSuspensions(playerCards);

    return newTeams;
}

const checkForInjury = (fouledPlayer, team, isRedCard, isOverworked = false) => {
    /** Function to determine whether a player has been injured */
    const calculateInjuryProbability = (player) => {
        const age = parseInt(player.age.split(";")[1], 10);
        let baseProbability = 0.08;

        if (!isOverworked) {
            const stamina = parseInt(player.stamina, 10);
            const pace = parseInt(player.pace, 10);
            const strength = parseInt(player.strength, 10);

            //console.log(`${player.name}'s age: ${age}, stamina: ${stamina}, strength: ${strength}, pace: ${pace}`);

            if (age > 30) {
                baseProbability *= 2;
            } else if (age > 25) {
                baseProbability *= 1.5;
            }

            baseProbability -= (stamina / 3000);
            baseProbability -= (strength / 3000);
            baseProbability += (pace / 3000);
        } else {
            const staminaThreshold = 40;
            const staminaFactor = player.currentStamina < staminaThreshold ? (staminaThreshold - player.currentStamina) / 100 : 0.005;
            const strengthFactor = 1 - parseInt(player.strength) / 100;
            const ageFactor = age > 30 ? (age - 30) / 50 : 0.005;

            baseProbability = 0.05 * staminaFactor * (1 + ageFactor) * strengthFactor;
        }


        return Math.min(Math.max(baseProbability, 0.001), 0.3);
    }

    const calculateInjurySeverity = (isRedCard) => {
        const getRandomMatchesOut = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        let severityProbability = {
            mild: 0.6,
            moderate: 0.3,
            severe: 0.1
        }

        if (isOverworked) {
            severityProbability = {
                mild: 0.8,
                moderate: 0.15,
                severe: 0.05
            }
        }

        if (isRedCard) {
            severityProbability.mild -= 0.2;
            severityProbability.moderate += 0.1;
            severityProbability.severe += 0.1;
        }

        const roll = Math.random();

        if (roll < severityProbability.mild) {
            return { severity: 'mild', matchesOut: getRandomMatchesOut(1, 2) };
        } else if (roll < severityProbability.mild + severityProbability.moderate) {
            return { severity: 'moderate', matchesOut: getRandomMatchesOut(3, 5) };
        } else {
            return { severity: 'severe', matchesOut: getRandomMatchesOut(6, 10) };
        }
    };

    const player = findPlayerInTeam(fouledPlayer, team);
    //console.log(`Checking ${player.name} for injury..`);
    if (!player) return { isInjured: false };

    const injuryProbability = calculateInjuryProbability(player);
    //console.log(`${fouledPlayer} injury probability: ${injuryProbability}`);
    const roll = Math.random();

    if (roll < injuryProbability) {
        const injurySeverity = calculateInjurySeverity(isRedCard);
        return { isInjured: true, severity: injurySeverity.severity, matchesOut: injurySeverity.matchesOut };
    }

    return { isInjured: false };
};

const handleInjuries = (result, match, playerCards) => {
    const fouls = [...result.yellowCards, ...result.redCards];
    const injuredPlayers = [];
    fouls.forEach(foul => {
        const team = foul.team === match.home.displayName ? match.away : match.home;
        const fouledKey = `${foul.fouledPlayer}-${team.displayName}`;
        if (!playerCards[fouledKey]) {
            playerCards[fouledKey] = {
                team: team.displayName,
                yellowCards: 0,
                redCards: 0,
                suspension: 0,
                isInjured: false
            };
        }

        const isRedCard = result.redCards.some(redCard => redCard.name === foul.name && redCard.minute === foul.minute);
        //console.log('Was a red card?:', isRedCard);
        const injuryCheck = checkForInjury(foul.fouledPlayer, team, isRedCard);

        if (injuryCheck.isInjured) {
            //console.log(`${foul.fouledPlayer} was injured in ${result.round}! Severity: ${injuryCheck.severity}, Matches out: ${injuryCheck.matchesOut}`);
            playerCards[fouledKey].suspension += injuryCheck.matchesOut;
            playerCards[fouledKey].isInjured = true;
            //console.log("Injured player's stats", playerCards[foul.fouledPlayer]);
            injuredPlayers.push({ name: foul.fouledPlayer, team: team.displayName });
        }
    });

    //Checks for overworked players in home teams
    Object.keys(match.home.positionsData).forEach(playerKey => {
        const player = match.home.positionsData[playerKey];
        if (player.currentStamina > 50) return;

        const injuryCheck = checkForInjury(player.name, match.home, false, true);
        if (injuryCheck.isInjured) {
            //console.log(`${player.name} was injured from exhaustion in Round ${result.round}. Severity: ${injuryCheck.severity}, Matches out: ${injuryCheck.matchesOut}\nCurrent stamina: ${player.currentStamina}`);
            const playerKey = `${player.name}-${match.home.displayName}`;

            playerCards[playerKey] = {
                team: match.home.displayName,
                yellowCards: 0,
                redCards: 0,
                suspension: injuryCheck.matchesOut,
                isInjured: true
            };

            injuredPlayers.push({ name: player.name, team: match.home.displayName });
        }
    });

    //Checks for overworked players in away teams
    Object.keys(match.away.positionsData).forEach(playerKey => {
        const player = match.away.positionsData[playerKey];
        if (player.currentStamina > 50) return;

        const injuryCheck = checkForInjury(player.name, match.away, false, true);
        if (injuryCheck.isInjured) {
            //console.log(`${player.name} was injured from exhaustion in Round ${result.round}. Severity: ${injuryCheck.severity}, Matches out: ${injuryCheck.matchesOut}\nCurrent stamina: ${player.currentStamina}`);
            const playerKey = `${player.name}-${match.away.displayName}`;
            playerCards[playerKey] = {
                team: match.away.displayName,
                yellowCards: 0,
                redCards: 0,
                suspension: injuryCheck.matchesOut,
                isInjured: true
            };

            injuredPlayers.push(player.name);
            injuredPlayers.push({ name: player.name, team: match.away.displayName });
        }
    });

    return injuredPlayers;
}

const getMatchDetailsByMinute = (result) => {
    const parseMinute = (minute) => {
        const minuteStr = minute.toString().replace('(P)', '');

        if (minuteStr.includes('+')) {
            const [regular, stoppage] = minuteStr.split('+').map(Number);
            return regular + stoppage / 100;
        }
        return Number(minuteStr);
    };

    const fixtureEvents = [];
    const yellowCardTracker = {};

    if (result.goalScorers.length > 0) {
        result.goalScorers.forEach(event => {
            fixtureEvents.push({
                type: 'goal',
                team: event.team,
                name: event.name,
                assister: event.assister,
                minute: event.minute
            });
        });
    }

    if (result.substitutions['team1'].length > 0 && result.substitutions['team2'].length > 0) {
        Object.keys(result.substitutions).forEach(team => {
            const teamName = team === 'team1' ? result.team1.displayName : result.team2.displayName;
            result.substitutions[team].forEach(substitution => {
                fixtureEvents.push({
                    type: 'substitution',
                    team: teamName,
                    substitute: substitution.substitute,
                    name: substitution.substitutedPlayer,
                    minute: substitution.minute
                });
            });
        });
    }

    if (result.yellowCards.length > 0) {
        result.yellowCards.forEach(event => {
            // Track yellow cards for the player
            if (!yellowCardTracker[event.name]) {
                yellowCardTracker[event.name] = { count: 0, minute: [] };
            }
            yellowCardTracker[event.name].count++;
            yellowCardTracker[event.name].minute.push(event.minute);

            // If the player receives two yellow cards, mark as 'yellowToRed'
            if (yellowCardTracker[event.name].count === 2) {
                fixtureEvents.push({
                    type: 'yellowToRed',
                    team: event.team,
                    name: event.name,
                    minute: yellowCardTracker[event.name].minute[1] // The second yellow card
                });
            } else {
                // Otherwise, it's a single yellow card
                fixtureEvents.push({
                    type: 'yellowCard',
                    team: event.team,
                    name: event.name,
                    minute: event.minute
                });
            }
        });
    }

    if (result.redCards.length > 0) {
        result.redCards.forEach(event => {
            fixtureEvents.push({
                type: 'redCard',
                team: event.team,
                name: event.name,
                minute: event.minute
            });
        });
    }

    if (result.missedPenalties.length > 0) {
        result.missedPenalties.forEach(penalty => {
            fixtureEvents.push({
                type: 'missedPenalty',
                team: penalty.team,
                name: penalty.player,
                minute: penalty.minute
            })
        });
    }

    fixtureEvents.sort((a, b) => parseMinute(a.minute) - parseMinute(b.minute));

    const motmTeam = result.team1.displayName === result.manOfTheMatch.team ? result.team1 : result.team2;
    const motmPlayer = findPlayerInTeam(result.manOfTheMatch.player, motmTeam);
    const manOfTheMatch = { ...result.manOfTheMatch, fullName: motmPlayer.fullName || motmPlayer.name, country: motmPlayer.nationality };
    //console.log('Man of the match:', manOfTheMatch, 'in team:', motmTeam);

    const fixtureResult = {
        home: result.team1.displayName,
        away: result.team2.displayName,
        homeGoals: result.team1Goals,
        awayGoals: result.team2Goals,
        events: fixtureEvents,
        manOfTheMatch,
        team1: result.team1,
        team2: result.team2
    }

    return fixtureResult;
};

const findPlayerInTeams = (playerName, teams) => {
    for (const team of teams) {
        // Search through startingXI, bench, and reserves
        const positionsData = Object.values(team.positionsData);
        const allPlayers = [...positionsData, ...team.bench, ...team.reserves];
        const player = allPlayers.find(p => p.name === playerName);

        if (player) {
            return player; // Return the player object if found
        }
    }
    return null; // Return null if player is not found
}

const simulateLeague = async (leagueId, teams, playerData) => {
    const findPlayerById = (id) => {
        const player = playerData.find(player => parseInt(player.id) === parseInt(id));
        if (!player) {
            missingPlayers.push(id);
            console.error(`Player with ID ${id} not found`);
        }
        return player;
    }

    const setTeamPositions = (positions, addKey = false) => {
        if (addKey) {
            const formattedPositions = {};
            positions.forEach(position => {
                const player = findPlayerById(position.id);
                if (player) {
                    // Clone the player object to avoid unintended mutations
                    const playerClone = { ...player, currentPosition: position.currentPosition, isStarter: true };

                    // Assign player to the corresponding position key
                    formattedPositions[playerClone.currentPosition.key] = playerClone;
                }
            });

            return formattedPositions;
        } else {
            return positions.map(position => {
                const player = findPlayerById(position);
                player.isStarter = false;
                return player;
            });
        }
    };

    const generateMatchdaySchedule = (startDate, endDate, totalMatchdays, leagueId) => {
        let preferredDays, matchesPerDay, additionalGaps;
        let winterBreakStart = new Date('December 24, 2024');
        let winterBreakEnd = new Date('January 1, 2025');

        if (leagueId === 13) {
            // Premier League (Friday, Saturday, Sunday)
            preferredDays = [5, 6, 0]; // Friday, Saturday, Sunday
            matchesPerDay = [3, 4, 3]; // 3 matches on Friday, 4 on Saturday, 3 on Sunday
        } else if (leagueId === 31) {
            preferredDays = [5, 6, 0];
            matchesPerDay = [2, 4, 4];
        } else if (leagueId === 53) {
            // La Liga (Thursday to Monday)
            preferredDays = [4, 5, 6, 0, 1]; // Thursday, Friday, Saturday, Sunday, Monday
            matchesPerDay = [2, 2, 2, 2, 2]; // 2 matches each day from Thursday to Monday
            winterBreakStart = new Date('December 23, 2024');
            winterBreakEnd = new Date('January 8, 2025');
        } else if (leagueId === 19 || leagueId === 16) { // Bundesliga and Ligue 1
            preferredDays = [5, 6, 0]; // Friday, Saturday, Sunday
            matchesPerDay = [1, 6, 2]; // 1 match on Friday, 6 on Saturday, 2 on Sunday
            winterBreakStart = new Date('December 23, 2024');
            winterBreakEnd = new Date('January 9, 2025');
            additionalGaps = [2, 6, 10];
        }

        const matchSchedule = [];
        const matchdayInterval = 7; // Regular weekly interval


        let currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + 1);
        let matchdayCount = 0;

        if (!preferredDays.includes(currentDate.getDay())) {
            // Move the date to the first preferred day (e.g., Friday)
            while (!preferredDays.includes(currentDate.getDay())) {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        while (currentDate <= new Date(endDate) && matchdayCount < totalMatchdays) {
            // Skip Christmas Eve to New Year's Day
            if (currentDate >= winterBreakStart && currentDate <= winterBreakEnd) {
                // Move to the first available matchday after the break
                currentDate = new Date(winterBreakEnd);
                currentDate.setDate(currentDate.getDate() + 1); // Start on Jan 2, 2025
                continue;
            }

            while (!preferredDays.includes(currentDate.getDay())) {
                currentDate.setDate(currentDate.getDate() + 1);
            }
            // Schedule the 10 matches across Friday, Saturday, Sunday
            const matchdaySchedule = [];

            preferredDays.forEach((day, index) => {
                for (let i = 0; i < matchesPerDay[index]; i++) {
                    matchdaySchedule.push({
                        matchday: matchdayCount + 1,
                        date: currentDate.toISOString().split('T')[0],
                        day: currentDate.toLocaleString('en-US', { weekday: 'long' }) // Add the day name
                    });
                }
                currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
            });

            matchSchedule.push(matchdaySchedule);
            matchdayCount++; // Increase the matchday count after scheduling all 10 matches

            if ((leagueId === 19 || leagueId === 16) && additionalGaps.includes(matchdayCount)) {
                currentDate.setDate(currentDate.getDate() + 7); // Add a one-week gap
            }

            currentDate.setDate(currentDate.getDate() + matchdayInterval - preferredDays.length);
        }

        return matchSchedule;
    };

    const formatTeamData = (teams) => {
        return teams.map(team => {
            const formattedTeam = { ...team };
            formattedTeam.positionsData = setTeamPositions(formattedTeam.positionsData, true);
            formattedTeam.bench = setTeamPositions(formattedTeam.bench);
            formattedTeam.reserves = setTeamPositions(formattedTeam.reserves);
            const captain = Object.values(formattedTeam.positionsData).find(player => String(player.id) === String(team.captain));
            formattedTeam.captain = captain;
            formattedTeam.displayName = formattedTeam.team_name;
            delete formattedTeam.team_name; // Remove the original team_name attribute
            return formattedTeam;
        });
    };

    const recoverStaminaBetweenMatches = (previousMatchday, currentMatchday, formattedTeams) => {
        const calculateStaminaRecovery = (player, daysBetweenMatches) => {
            if (player.currentStamina === 100) return;
            const physical = parseInt(player.physical);
            const strength = parseInt(player.strength);
            const age = parseInt(player.age.split('; ')[1]);

            const baseRecoveryRate = 0.15; // Base recovery rate
            const ageFactor = age > 30 ? (age - 30) * 0.5 : 0;

            const recoveryRate = ((physical + strength) / 2) * (1 - ageFactor / 100) * baseRecoveryRate;
            const totalRecovery = recoveryRate * daysBetweenMatches;

            return Math.min(100, player.currentStamina + totalRecovery);
        }

        const calculateDaysBetween = (date1, date2) => {
            if (!date1 || !date2) return 0;
            const firstDate = new Date(date1);
            const secondDate = new Date(date2);
            return (secondDate - firstDate) / (1000 * 60 * 60 * 24);
        };

        const findMatchForTeam = (teamName, matchday) => {
            if (!matchday) console.error('Matchday is undefined:', teamName);
            return matchday.find(match => match.home.displayName === teamName || match.away.displayName);
        }

        currentMatchday.forEach(match => {
            //Works
            const homeMatchPrevious = findMatchForTeam(match.home.displayName, previousMatchday);
            const awayMatchPrevious = findMatchForTeam(match.away.displayName, previousMatchday);

            const homeDaysBetween = calculateDaysBetween(homeMatchPrevious?.date, match.date);
            const awayDaysBetween = calculateDaysBetween(awayMatchPrevious?.date, match.date);

            if (homeDaysBetween) {
                const updatedHomePlayers = [...Object.values(match.home.positionsData), ...match.home.bench, ...match.home.reserves];
                updatedHomePlayers.forEach(player => {
                    player.currentStamina = calculateStaminaRecovery(player, homeDaysBetween);
                });

                // Update the formattedTeams with the new stamina values
                let formattedHomeTeam = formattedTeams.find(team => String(team.team_id) === String(match.home.team_id));
                if (formattedHomeTeam) {
                    formattedHomeTeam = match.home;
                }
            }

            // Recover stamina for away team players
            if (awayDaysBetween) {
                const updatedAwayPlayers = [...Object.values(match.away.positionsData), ...match.away.bench, ...match.away.reserves];
                updatedAwayPlayers.forEach(player => player.currentStamina = calculateStaminaRecovery(player, awayDaysBetween));

                // Update the formattedTeams with the new stamina values
                let formattedAwayTeam = formattedTeams.find(team => String(team.team_id) === String(match.away.team_id));
                if (formattedAwayTeam) {
                    formattedAwayTeam = match.away;
                }
            }
        });
    };

    const calculateStamina = (result, teams) => {
        const calculateMinutesPlayed = (player, substitutions, isSubstitute) => {
            const sub = substitutions.find(sub => sub.substitutedPlayer === player.name || sub.substitute === player.name);

            if (sub && isSubstitute) {
                // For a player substituted in, calculate minutes played as 90 - minute
                const minute = sub.minute.toString();
                return minute.includes('+') ? 89 : 90 - parseInt(minute);
            } else if (sub) {
                // For a player substituted out, calculate minutes played as the minute they were substituted
                const minute = sub.minute.toString();
                const minutesPlayed = minute.includes('+') ? 89 : parseInt(minute);
                //if (player.id === '218667' || player.id === '239085') console.log(`${player.name} was substituted and played for ${minutesPlayed} minutes`);
                return minutesPlayed;
            }

            //if (player.id === '218667' || player.id === '239085') console.log(`${player.name} was not substituted`);
            return 90;
        };

        const calculateStaminaLoss = (player, minutesPlayed) => {
            const baseDecayRate = 1;
            const staminaFactor = parseInt(player.stamina) / 50;
            let decayRate = baseDecayRate / staminaFactor;

            if (player.currentPosition.name === 'GK') {  //NEW: Check if the player is a goalkeeper
                decayRate /= 5;  //NEW: Reduce the decay rate by dividing it by 5
            } else if (player.currentPosition.name.includes('B')) {
                decayRate /= 1.25;
            }

            const staminaDecrease = decayRate * minutesPlayed;
            //console.log(`${player.name} stamina loss: ${staminaDecrease}, Decay rate: ${decayRate}, Stamina factor: ${staminaFactor}, Minutes played: ${minutesPlayed}`);
            //if (player.id === '218667' || player.id === '239085') 
            return Math.max(0, 100 - staminaDecrease);
        };

        const updatePlayerStamina = (team, substitutions) => {
            const gameSubstitutes = team.bench.filter(player => substitutions.some(sub => sub.substitute === player.name));
            const allPlayers = [...Object.values(team.positionsData), ...gameSubstitutes];
            allPlayers.forEach(player => {
                const isSubstitute = substitutions.some(sub => sub.substitute === player.name);
                let minutesPlayed = 0;
                minutesPlayed = calculateMinutesPlayed(player, substitutions, isSubstitute);
                player.currentStamina = calculateStaminaLoss(player, minutesPlayed);
                //if (player.currentStamina !== 100) console.log(`${player.name}'s stamina after match: ${player.currentStamina}`);
            });
        };

        updatePlayerStamina(teams.team1, result.substitutions.team1);
        updatePlayerStamina(teams.team2, result.substitutions.team2);
    };

    const assignMatchdaysToFixtures = (fixtures, startDate, endDate, leagueId) => {
        const totalMatchdays = fixtures.length;
        const matchSchedule = generateMatchdaySchedule(startDate, endDate, totalMatchdays, leagueId);

        //console.log(`${leagueId} match schedule:`, matchSchedule);

        return fixtures.map((round, roundIndex) => {
            return round.map((match, matchIndex) => {
                const scheduledMatch = {
                    ...match,
                    date: matchSchedule[roundIndex][matchIndex] ? matchSchedule[roundIndex][matchIndex].date : 'error',
                    day: matchSchedule[roundIndex][matchIndex].day // Include the day of the week
                };
                return scheduledMatch;
            });
        });
    }

    const updatePlayerAppearances = (team, substitutions) => {
        Object.values(team.positionsData).forEach(player => {
            player.appearances++;
        });

        substitutions.forEach(substitution => {
            const substitutePlayer = findPlayerInTeam(substitution.substitute, team);
            if (substitutePlayer) {
                substitutePlayer.appearances++;
            }
        });
    };

    const finalizeAppearances = (goalScorers, assisters, formattedTeams) => {
        Object.keys(goalScorers).forEach(playerKey => {
            const playerTeam = formattedTeams.find(team => team.displayName === goalScorers[playerKey].team);
            if (playerTeam) {
                const player = findPlayerInTeam(goalScorers[playerKey].name, playerTeam);
                if (player) {
                    goalScorers[playerKey].appearances = player.appearances || 0;
                }
            }
        });

        Object.keys(assisters).forEach(playerName => {
            const playerTeam = formattedTeams.find(team => team.displayName === assisters[playerName].team);
            if (playerTeam) {
                const player = findPlayerInTeam(assisters[playerName].name, playerTeam);
                if (player) {
                    assisters[playerName].appearances = player.appearances || 0;
                }
            }
        });
    };

    const getUpdatedTeam = (formattedTeams, teamId) => {

        const updatedTeam = formattedTeams.find(team => String(team.team_id) === String(teamId));
        //adjustStartingLineup(updatedTeam);
        return updatedTeam;
    }

    let formattedTeams = formatTeamData(teams);
    let originalTeams = JSON.parse(JSON.stringify(formattedTeams));
    //console.log('Original teams:', originalTeams);

    //Gets missing players
    if (missingPlayers.length > 0) {
        console.log('Missing players:', missingPlayers);
    }
    initializeAppearances(formattedTeams);
    const leagueTable = formattedTeams.map(team => ({
        displayName: team.displayName,
        played: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        goals_for: 0,
        goals_against: 0,
        goal_difference: 0,
        points: 0,
    }));

    const fixtures = generateFixtures(formattedTeams);
    let startDate = '';
    let endDate = '';

    if (leagueId === 13 || leagueId === 31) {
        startDate = 'August 16, 2024';
        endDate = 'May 25, 2025'
    } else if (leagueId === 53) {
        startDate = 'August 15, 2024';
        endDate = 'May 25, 2025'
    } else if (leagueId === 19 || leagueId === 16) {
        startDate = 'August 23, 2024';
        endDate = 'May 17, 2025';
    }

    const scheduledFixtures = assignMatchdaysToFixtures(fixtures, startDate, endDate, leagueId);
    //console.log('Scheduled fixtures:', scheduledFixtures);
    const fixtureResults = [];
    const goalScorers = {};
    const assisters = {};
    const goalkeepers = [];
    const hatTricks = {};
    const playerCards = {};
    const playerRatings = {};

    formattedTeams.forEach(team => {
        const startingGoalkeeper = team.positionsData['Goalkeeper-0'];
        const benchGoalkeepers = team.bench.find(player => player.position.includes('GK'));
        const reserveGoalkeepers = team.reserves.filter(player => player.position.includes('GK'));

        const allGoalkeepers = [startingGoalkeeper, benchGoalkeepers, ...reserveGoalkeepers];
        allGoalkeepers.forEach(goalkeeper => {
            if (goalkeeper) {
                goalkeeper.appearances = 0;
                goalkeeper.cleanSheets = 0;
            }
        })
    });

    for (let roundIndex = 0; roundIndex < scheduledFixtures.length; roundIndex++) {
        const round = scheduledFixtures[roundIndex];

        /** Debug to print positionsData */
        const roundResults = [];

        /* Debug to avoid too much data 
        if (roundIndex > 25) {
            break;
        }*/

        if (roundIndex > 0) {
            const previousRound = scheduledFixtures[roundIndex - 1];
            recoverStaminaBetweenMatches(previousRound, round, formattedTeams);
        }

        // if (exhaustedPlayers.length > 0) restoreExhaustedPlayers(formattedTeams);

        for (let matchIndex = 0; matchIndex < round.length; matchIndex++) {
            const match = round[matchIndex];

            // Uses old match teams
            if (roundIndex > 0) {
                match.home = getUpdatedTeam(formattedTeams, match.home.team_id);
                match.away = getUpdatedTeam(formattedTeams, match.away.team_id);
            }

            const matchResult = await simulateMatch(match.home, match.away, match.home, false);
            const result = { ...matchResult, round: roundIndex + 1 };

            const ratings = result.ratings;

            calculateStamina(result, { team1: match.home, team2: match.away });
            trackCards(leagueId, result, playerCards);
            const injuries = handleInjuries(result, match, playerCards);
            //if (injuries.length > 0) console.log('Injures from match:', injuries);

            if (roundIndex > 0) {
                const restoreCaptain = (teamId) => {
                    const originalCaptain = originalTeams.find(t => t.team_id === teamId)?.captain;
                    const formattedTeam = formattedTeams.find(oldTeam => oldTeam.team_id === teamId);
                    if (originalCaptain && parseInt(formattedTeam.captain.id) !== parseInt(originalCaptain.id) && !formattedTeam.reserves.some(p => parseInt(p.id) === parseInt(originalCaptain.id))) {
                        //console.log(`Captain ${originalCaptain.name} was substituted and was replaced by ${team.captain.name}`);
                        formattedTeam.captain = originalCaptain;
                        //console.log("Team's captain was replaced: ", team);
                    }
                }

                restoreCaptain(match.home.team_id);
                restoreCaptain(match.away.team_id);
            }
            updateLeagueTable(leagueTable, match.home, result.team1Goals, result.team2Goals);
            updateLeagueTable(leagueTable, match.away, result.team2Goals, result.team1Goals);

            updatePlayerAppearances(match.home, result.substitutions.team1);
            updatePlayerAppearances(match.away, result.substitutions.team2);

            updateCleanSheets(match.home, match.away, result.team1Goals, result.team2Goals);

            const goalsByPlayer = {};

            if (result.goalScorers) {
                result.goalScorers.forEach(goal => {
                    if (!goal.name.includes('o.g.')) {
                        const playerTeam = formattedTeams.find(team => team.displayName === goal.team);
                        const player = findPlayerInTeam(goal.name, playerTeam);
                        if (!player) {
                            console.error(`${goal.name} could not be found in ${goal.team}`);
                        }

                        const goalKey = `${goal.name}-${player.id}`;

                        if (!goalScorers[goalKey]) {
                            goalScorers[goalKey] = {
                                name: goal.name,
                                team: goal.team,
                                goals: 0, assists: 0,
                                photo: player ? player.photo : '',
                                fullName: player ? player.fullName : goal.name,
                                country: player ? player.nationality : '',
                                appearances: player.appearances
                            };
                        }
                        goalScorers[goalKey].goals++;
                        goalScorers[goalKey].appearances = player.appearances;

                        if (!goalsByPlayer[goalKey]) {
                            goalsByPlayer[goalKey] = {
                                goals: 0,
                                times: []
                            };
                        }
                        goalsByPlayer[goalKey].goals++;
                        goalsByPlayer[goalKey].times.push(goal.minute);

                        // Track hat-tricks
                        if (goalsByPlayer[goalKey].goals >= 3) {
                            if (!hatTricks[result.round]) {
                                hatTricks[result.round] = [];
                            }

                            const existingHatTrick = hatTricks[result.round].find(ht => ht.player === goal.name);

                            const isHomeTeam = goal.team === match.home.displayName;
                            const scoreline = isHomeTeam
                                ? `${result.team1Goals} - ${result.team2Goals}`
                                : `${result.team2Goals} - ${result.team1Goals}`;

                            if (existingHatTrick) {
                                // Update the existing hat-trick entry
                                existingHatTrick.times = goalsByPlayer[goalKey].times;
                                existingHatTrick.scoreline = scoreline;
                            } else {
                                // Add a new hat-trick entry
                                hatTricks[result.round].push({
                                    player: goal.name,
                                    photo: player ? player.photo : '',
                                    country: player ? player.nationality : '',
                                    flag: player ? player.flag : '',
                                    fullName: player ? player.fullName : '',
                                    forTeam: goal.team,
                                    againstTeam: goal.team === match.home.displayName ? match.away.displayName : match.home.displayName,
                                    scoreline: scoreline,
                                    homeOrAway: isHomeTeam ? '(H)' : '(A)',
                                    matchDay: result.round,
                                    times: goalsByPlayer[goalKey].times
                                });
                            }
                        }

                        if (goal.assister && goal.assister !== "") {
                            const assisterPlayer = findPlayerInTeam(goal.assister, playerTeam);
                            if (!assisterPlayer) {
                                console.error(`${goal.name} could not be found in ${playerTeam.displayName}`);
                            }
                            const assistKey = `${goal.assister}-${assisterPlayer.id}`;

                            if (!assisters[assistKey]) {
                                assisters[assistKey] = {
                                    name: goal.assister,
                                    team: goal.team, assists: 0,
                                    photo: assisterPlayer ? assisterPlayer.photo : '',
                                    fullName: assisterPlayer ? assisterPlayer.fullName : goal.assister,
                                    country: assisterPlayer ? assisterPlayer.nationality : '',
                                    appearances: 0
                                };
                            }
                            assisters[assistKey].assists++;
                            //assisters[assistKey].appearances = assisterPlayer.appearances; //Update player appearances
                            // Also increment assist count in goalScorers
                            if (goal.assister !== goal.name) {
                                if (goalScorers[assistKey]) {
                                    goalScorers[assistKey].assists = assisters[assistKey].assists;
                                }
                            }
                        }
                    }
                });
            }

            Object.keys(ratings).forEach(playerName => {
                const { team, rating } = ratings[playerName];
                const playerTeam = formattedTeams.find(t => t.displayName === team);
                const playerObject = findPlayerInTeam(playerName, playerTeam);

                const playerKey = `${playerName}-${team}`;

                if (!playerRatings[playerKey]) {
                    playerRatings[playerKey] = {
                        name: playerName,
                        fullName: playerObject ? playerObject.fullName : playerName,
                        country: playerObject ? playerObject.nationality : '',
                        photo: playerObject ? playerObject.photo : '',
                        team: team,
                        totalRating: 0,
                        appearances: 0
                    };
                }

                playerRatings[playerKey].totalRating += rating;
                playerRatings[playerKey].appearances++;
            })

            let fixtureResult = getMatchDetailsByMinute(result);
            fixtureResult = { ...fixtureResult, date: match.date, injuries: injuries, id: `${roundIndex}-${matchIndex}` };
            roundResults.push(fixtureResult);
        }

        formattedTeams = await handleSuspensionsAndAdjustments(formattedTeams, playerCards);
        fixtureResults.push(roundResults);
    }

    finalizeAppearances(goalScorers, assisters, formattedTeams);
    formattedTeams.forEach(team => {
        const startingGoalkeeper = team.positionsData['Goalkeeper-0'];
        const benchGoalkeepers = team.bench.find(player => player.position.includes('GK'));
        const reserveGoalkeepers = team.reserves.filter(player => player.position.includes('GK'));

        const allGoalkeepers = [startingGoalkeeper, benchGoalkeepers, ...reserveGoalkeepers];

        allGoalkeepers.forEach(goalkeeper => {
            if (goalkeeper && goalkeeper.cleanSheets > 0) {
                goalkeepers.push({
                    name: goalkeeper.name,
                    fullName: goalkeeper.fullName,
                    country: goalkeeper.nationality,
                    team: team.displayName,
                    photo: goalkeeper.photo,
                    appearances: goalkeeper.appearances,
                    cleanSheets: goalkeeper.cleanSheets,
                });
            }
        });
    })

    const topGoalkeepers = goalkeepers.sort((a, b) => b.cleanSheets - a.cleanSheets).slice(0, teams.length);
    //console.log('Formatted teams:', formattedTeams);

    const sortLeagueTable = (leagueTable, fixtureResults) => {
        const getHeadToHead = (teams) => {
            const headToHeadRecord = {};

            teams.forEach(team => {
                headToHeadRecord[team] = {
                    points: 0,
                    goalDifference: 0,
                    goalsFor: 0,
                    goalsAgainst: 0,
                    awayGoals: 0
                };
            });
            fixtureResults.flatMap(fixture => fixture).forEach(match => {
                const homeTeam = match.home;
                const awayTeam = match.away;

                if (teams.includes(homeTeam) && teams.includes(awayTeam)) {
                    const homeGoals = match.homeGoals;
                    const awayGoals = match.awayGoals;

                    // Update home team record
                    headToHeadRecord[homeTeam].goalsFor += homeGoals;
                    headToHeadRecord[homeTeam].goalsAgainst += awayGoals;
                    headToHeadRecord[homeTeam].goalDifference += homeGoals - awayGoals;
                    headToHeadRecord[homeTeam].awayGoals += 0; // No away goals for home team

                    // Update away team record
                    headToHeadRecord[awayTeam].goalsFor += awayGoals;
                    headToHeadRecord[awayTeam].goalsAgainst += homeGoals;
                    headToHeadRecord[awayTeam].goalDifference += awayGoals - homeGoals;
                    headToHeadRecord[awayTeam].awayGoals += awayGoals;

                    // Assign points for the match
                    if (homeGoals > awayGoals) {
                        headToHeadRecord[homeTeam].points += 3;
                    } else if (awayGoals > homeGoals) {
                        headToHeadRecord[awayTeam].points += 3;
                    } else {
                        headToHeadRecord[homeTeam].points += 1;
                        headToHeadRecord[awayTeam].points += 1;
                    }
                }
            });

            return headToHeadRecord;
        };

        const getGoalDifference = (goalDifference) => {
            return goalDifference >= 0 ? `+${goalDifference}` : `${goalDifference}`;
        }

        const createComparisonExplanation = (teams, attribute, attributeName, recordSource, headToHeadRecord = null) => {
            let teamValues = teams.map(team => {
                let value;
                if (recordSource === 'headToHead') {
                    value = headToHeadRecord[team.displayName][attribute];
                } else {
                    value = team[attribute];
                }
                return { team, value };
            });

            teamValues.sort((a, b) => b.value - a.value);

            let explanation = `${teamValues[0].team.displayName} ranks higher due to better ${attributeName}: ` +
                teamValues.map(tv => `${tv.team.displayName} (${attribute.includes('ifference') ? getGoalDifference(tv.value) : tv.value})`).join(' > ');

            return explanation;
        };

        const tiebreakerPriority = (teamA, teamB, tiedTeams, headToHeadRecord, leagueId) => {
            let explanation;
            const teamARecord = headToHeadRecord[teamA.displayName];
            const teamBRecord = headToHeadRecord[teamB.displayName];

            const tiebreakerOrder = {
                // Bundesliga and Premier League
                13: ['goal_difference', 'goals_for', 'points', 'goalDifference', 'awayGoals'],
                19: ['goal_difference', 'goals_for', 'points', 'goalDifference', 'awayGoals'],
                // La Liga and Serie A
                31: ['points', 'goalDifference', 'goal_difference', 'goals_for'],
                53: ['points', 'goalDifference', 'goal_difference', 'goals_for'],
                // Ligue 1
                16: ['goal_difference', 'points', 'goalDifference', 'goalsFor', 'awayGoals', 'goals_for']
            }[leagueId];

            for (let i = 0; i < tiebreakerOrder.length; i++) {
                const attribute = tiebreakerOrder[i];

                if (attribute === 'goal_difference') {
                    if (teamA.goal_difference !== teamB.goal_difference) {
                        explanation = createComparisonExplanation(tiedTeams, 'goal_difference', 'overall goal difference', 'overall');
                        tiedTeams.forEach(team => team.tiebreakerExplanation = explanation);
                        return teamB.goal_difference - teamA.goal_difference;
                    }
                } else if (attribute === 'goals_for') {
                    if (teamA.goals_for !== teamB.goals_for) {
                        explanation = createComparisonExplanation(tiedTeams, 'goals_for', 'overall goals scored', 'overall');
                        tiedTeams.forEach(team => team.tiebreakerExplanation = explanation);
                        return teamB.goals_for - teamA.goals_for;
                    }
                } else if (attribute === 'points') {
                    if (teamARecord.points !== teamBRecord.points) {
                        explanation = createComparisonExplanation(tiedTeams, 'points', 'head-to-head points', 'headToHead', headToHeadRecord);
                        tiedTeams.forEach(team => team.tiebreakerExplanation = explanation);
                        return teamBRecord.points - teamARecord.points;
                    }
                } else if (attribute === 'goalDifference') {
                    if (teamARecord.goalDifference !== teamBRecord.goalDifference) {
                        explanation = createComparisonExplanation(tiedTeams, 'goalDifference', 'head-to-head goal difference', 'headToHead', headToHeadRecord);
                        tiedTeams.forEach(team => team.tiebreakerExplanation = explanation);
                        return teamBRecord.goalDifference - teamARecord.goalDifference;
                    }
                } else if (attribute === 'awayGoals') {
                    if (teamARecord.awayGoals !== teamBRecord.awayGoals) {
                        explanation = createComparisonExplanation(tiedTeams, 'awayGoals', 'head-to-head away goals', 'headToHead', headToHeadRecord);
                        tiedTeams.forEach(team => team.tiebreakerExplanation = explanation);
                        return teamBRecord.awayGoals - teamARecord.awayGoals;
                    }
                }
            }

            return teamA.displayName.localeCompare(teamB.displayName);
        };

        leagueTable.forEach(team => {
            team.tiebreakerExplanation = ''; // Initialize the explanation for each team
        });

        leagueTable.sort((a, b) => {
            if (b.points === a.points) {
                const tiedTeams = leagueTable.filter(t => t.points === b.points);

                if (tiedTeams.length > 1) {
                    const tiedTeamNames = tiedTeams.map(t => t.displayName);
                    const headToHeadRecord = getHeadToHead(tiedTeamNames, fixtureResults);

                    tiedTeams.sort((teamA, teamB) => tiebreakerPriority(teamA, teamB, tiedTeams, headToHeadRecord, leagueId));

                    return tiedTeams.indexOf(a) - tiedTeams.indexOf(b);
                }
            }

            return b.points - a.points;
        });

        return leagueTable;
    };

    const playersWithAverageRating = Object.values(playerRatings)
        .map(player => {
            const averageRating = player.totalRating / player.appearances;
            const weightedScore = (averageRating * player.appearances) / (player.appearances + 10);

            return {
                ...player,
                averageRating,
                weightedScore
            };
        })
        .sort((a, b) => {
            // First, sort by average rating (higher is better)
            if (b.weightedScore !== a.weightedScore) {
                return b.weightedScore - a.weightedScore;
            }
            // If the average ratings are the same, sort by appearances (higher is better)
            return b.appearances - a.appearances;
        })
        .slice(0, 30);  // Take the top 30 players 

    const sortedTable = sortLeagueTable(leagueTable, fixtureResults);
    //console.log('League table:', sortedTable);

    return {
        leagueTable: sortedTable,
        fixtureResults,
        goalScorers,
        assisters,
        topGoalkeepers,
        hatTricks,
        playerRatings: playersWithAverageRating
    };
}

export { addToLeague, simulateLeague };